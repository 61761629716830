import { useRouter } from "next/router";
import React, { useState, useEffect, createContext } from "react";

import { useCookies } from "../hooks/useCookies";
import { Localization, Locale } from "../types/locale";
import { isLocale } from "../utils/locale";

interface ContextProps {
    readonly localization: Localization;
    readonly setLocalizationState: (localization: Localization) => void;
}

export const LanguageContext = createContext<ContextProps>({
    localization: {
        locale: "global",
    },
    setLocalizationState: () => null,
});

export function useLanguageState(): ContextProps {
    const context = React.useContext(LanguageContext);
    if (context === undefined) {
        throw new Error("useLanguageState must be used within a LanguageProvider");
    }
    return context;
}

export function useCurrentLocale(): Locale {
    const context = React.useContext(LanguageContext);
    if (context === undefined) {
        throw new Error("useLanguageState must be used within a LanguageProvider");
    }
    return context.localization.locale || "global";
}

export const LocaleProvider: React.FC<{ locale: Locale }> = ({ locale, children }) => {
    const [localization, setLocalization] = useState({ locale });
    const [storedLocale, setStoredLocale] = useCookies("NEXT_LOCALE");
    const { query } = useRouter();
    useEffect(() => {
        if (localization.locale !== storedLocale) {
            setStoredLocale(localization.locale);
        }
    }, [localization.locale]);

    useEffect(() => {
        if (typeof query.lang === "string" && isLocale(query.lang)) {
            setLocalization({ locale });
        }
    }, [query.lang, localization.locale]);

    return (
        <LanguageContext.Provider value={{ localization, setLocalizationState: setLocalization }}>
            {children}
        </LanguageContext.Provider>
    );
};
