/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ContinuationStore } from "@/types/continuationLocalStorage";

export default {
    run: (o: ContinuationStore, callback: () => void) => callback(),
    getStore: () => null,
    enterWith: (o: ContinuationStore) => false,
};
