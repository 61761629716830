import React from "react";

// Please refer to here for enabling why did you render for a specific component
// https://github.com/welldone-software/why-did-you-render

if (typeof window !== "undefined" && process.env.NODE_ENV === "development") {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const whyDidYouRender = require("@welldone-software/why-did-you-render");

    // eslint-disable-next-line no-console
    console.debug(
        "Applying whyDidYouRender, to help you locate unnecessary re-renders during development. See https://github.com/welldone-software/why-did-you-render"
    );

    // See https://github.com/welldone-software/why-did-you-render#options
    whyDidYouRender(React, {
        trackAllPureComponents: true,
        trackHooks: true,
        logOwnerReasons: true,
        collapseGroups: true,
    });
}

export default {};
