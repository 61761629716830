export const testIsSupportedBrowser = (): boolean => {
    if (typeof window !== "undefined") {
        const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
        if (isIE) {
            return false;
        }
    }

    return true;
};
