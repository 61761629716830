import Cookies from "js-cookie";
import { useState } from "react";

import logger from "@/utils/logger";

export type SetValueFunction = (value: unknown) => void;
export function useCookies(key: string, initialValue = ""): [string, SetValueFunction] {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = Cookies.get(key);
            return item ?? initialValue;
        } catch (error) {
            logger.logException(error, `failed to use a cookie`);
            return initialValue;
        }
    });

    const setValue: SetValueFunction = (value: unknown) => {
        try {
            const valueToStore: string = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            Cookies.set(key, valueToStore);
        } catch (error) {
            logger.logException(error, `failed to set a cookie value`);
        }
    };
    return [storedValue, setValue];
}
