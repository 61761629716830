import { AxiosError } from "axios";
import Cookies from "cookies";
import { IncomingMessage, ServerResponse } from "http";
import { StatusCodes } from "http-status-codes";
import { v4 as uuidV4 } from "uuid";

import {
    AUTH_USER_DATA_COOKIE,
    AUTH_USER_DATA_STORE_KEY,
    DEFAULT_REQUEST_TIMEOUT,
    ONE_DAY,
    ONE_YEAR,
    TWO_MINUTES,
    REQUEST_ID_STORE_KEY,
} from "@/shared/constants";

import { createContinuationStore, getContinuationStore } from "./continuation-local-storage";

const generateUUID = (): string => {
    return `generated-id-${uuidV4()}`;
};

const extractRequestUniqueId = (req: IncomingMessage): string => {
    let header = req?.headers["x-request-id"];
    if (Array.isArray(header)) {
        header = header[0];
    }
    return header || generateUUID();
};

const getReqCookies = (req: IncomingMessage, res: ServerResponse): Cookies => {
    return new Cookies(req, res, { secure: false });
};

const extractRequestUserCookie = (req: IncomingMessage, res: ServerResponse): string => {
    const cookies = getReqCookies(req, res);
    return cookies.get(AUTH_USER_DATA_COOKIE);
};

export const createContinuationRequestContext = (
    req: IncomingMessage,
    res: ServerResponse
): void => {
    const store = createContinuationStore();

    const requestId = extractRequestUniqueId(req);
    store.set(REQUEST_ID_STORE_KEY, requestId);

    // TODO: SHOP-1244 and SHOP-1245 temporary fix - for now as we have all requests cached by Cloudflare,
    // We should avoid rendering user auth data in the backend. Comment this out when we're ready to render all auth requests without cache
    /*
    const clientCookieAuthUserData = extractRequestUserCookie(req, res);
    store.set(AUTH_USER_DATA_STORE_KEY, clientCookieAuthUserData);
    */
};

export const getContinuationRequestId = (forceGenerateID = false): string => {
    const fallbackRequestId = forceGenerateID ? generateUUID() : null;

    const store = getContinuationStore();
    return store?.get(REQUEST_ID_STORE_KEY)?.toString() || fallbackRequestId;
};

export const getContinuationUserCookie = (): string => {
    const store = getContinuationStore();
    const storeData = store?.get(AUTH_USER_DATA_STORE_KEY)?.toString();
    return storeData || null;
};

export const getNormelisedFetchOptions = (
    options?: Record<string, unknown>
): Record<string, unknown> => {
    return { timeout: DEFAULT_REQUEST_TIMEOUT, ...options };
};

export const isSilentError = (ex: AxiosError, silentErrors: Array<StatusCodes>): boolean => {
    return !!silentErrors?.find((errorCode) => ex?.response?.status === errorCode);
};

export const throwOrReturnDefault = <T>(
    ex: AxiosError,
    defaultValue: T = null,
    silentErrors: Array<StatusCodes> = [StatusCodes.NOT_FOUND]
): T => {
    if (!isSilentError(ex, silentErrors)) {
        throw ex;
    }

    return defaultValue;
};

export const getFirstIfArray = <T = string>(headerVal: T | T[]): T => {
    return Array.isArray(headerVal) ? headerVal[0] : headerVal;
};

type CacheHeaderData = { maxAge?: number; staleWhileRevalidating?: number; staleIfError?: number };
const getCacheHeader = (
    res: ServerResponse,
    { maxAge, staleWhileRevalidating, staleIfError }: CacheHeaderData
): void => {
    res?.setHeader?.(
        "cache-control",
        `public${!isNaN(maxAge) ? `, max-age=${maxAge}` : ``}${
            !isNaN(staleWhileRevalidating)
                ? `, stale-while-revalidate=${staleWhileRevalidating}`
                : ``
        }${!isNaN(staleIfError) ? `, stale-if-error=${staleIfError}` : ``}`
    );
};

export const applyCacheHeader = (res: ServerResponse): void => {
    getCacheHeader(res, {
        maxAge: TWO_MINUTES,
        staleWhileRevalidating: ONE_DAY,
        staleIfError: ONE_YEAR,
    });
};

export const applyResourcesCacheHeader = (res: ServerResponse): void => {
    getCacheHeader(res, {
        maxAge: ONE_DAY,
        staleWhileRevalidating: ONE_DAY,
        staleIfError: ONE_YEAR,
    });
};

export const applyStaleIfErrorCacheHeader = (res: ServerResponse): void => {
    getCacheHeader(res, {
        staleIfError: ONE_YEAR,
    });
};
