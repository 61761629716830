import React, { useState, createContext, useContext } from "react";
import { v4 } from "uuid";

interface ContextProps {
    readonly errorMessages: Record<string, string>;
    readonly createErrorMessage: (errorMessage: string) => void;
    readonly setErrorMessages: (errorMessages: Record<string, string>) => void;
}

export const ErrorMessageContext = createContext<ContextProps>({
    errorMessages: {},
    createErrorMessage: () => null,
    setErrorMessages: () => null,
});

export const useErrorMessage = (): ContextProps => {
    const context = useContext<ContextProps>(ErrorMessageContext);
    if (context === undefined) {
        throw new Error("useErrorMessage must be used within a ErrorMessageProvider");
    }
    return context;
};

export const ErrorMessageProvider: React.FC<{ errorMessages: Record<string, string> }> = ({
    errorMessages,
    children,
}) => {
    const [errorMessagesState, setErrorMessagesState] =
        useState<Record<string, string>>(errorMessages);
    const createErrorMessage = (message: string) => {
        setErrorMessagesState({
            ...errorMessagesState,
            [v4()]: message,
        });
    };
    return (
        <ErrorMessageContext.Provider
            value={{
                errorMessages: errorMessagesState,
                createErrorMessage,
                setErrorMessages: setErrorMessagesState,
            }}
        >
            {children}
        </ErrorMessageContext.Provider>
    );
};
