import { AsyncLocalStorage } from "async_hooks";

import { ContinuationStore } from "@/types/continuationLocalStorage";

import browserAsyncLocalStorageMock from "@/polyfills/AsyncLocalStoragePolyfill";

const asyncLocalStorage = AsyncLocalStorage
    ? new AsyncLocalStorage()
    : browserAsyncLocalStorageMock;

export const createContinuationStore = (): ContinuationStore => {
    const map = new Map<string, unknown>();
    asyncLocalStorage.enterWith(map);

    return getContinuationStore();
};

export const getContinuationStore = (): ContinuationStore => {
    return asyncLocalStorage.getStore() as ContinuationStore;
};
