import { RewriteFrames } from "@sentry/integrations";
import * as Sentry from "@sentry/nextjs";

import { GIT_COMMIT_SHA, IS_SERVER, SENTRY_DSN, SENTRY_SERVER_ROOT_DIR } from "../shared/constants";
import { IGNORE_ERRORS } from "../shared/ignore-errors.constants";

export const init = (): void => {
    if (SENTRY_DSN) {
        const integrations = [];
        if (IS_SERVER && SENTRY_SERVER_ROOT_DIR) {
            // For Node.js, rewrite Error.stack to use relative paths, so that source
            // maps starting with ~/_next map to files in Error.stack with path
            // app:///_next
            integrations.push(
                new RewriteFrames({
                    iteratee: (frame) => {
                        frame.filename = frame.filename.replace(SENTRY_SERVER_ROOT_DIR, "app:///");
                        frame.filename = frame.filename.replace(".next", "_next");
                        return frame;
                    },
                })
            );
        }

        Sentry.init({
            enabled: !!SENTRY_DSN,
            integrations,
            dsn: SENTRY_DSN,
            release: GIT_COMMIT_SHA,
            ignoreErrors: IGNORE_ERRORS,
        });
    }
};

init();

export default Sentry;
