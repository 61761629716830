import { AxiosError } from "axios";
import stringify from "json-stringify-safe";

import { IS_SERVER } from "@/shared/constants";

import { getContinuationRequestId } from "./requests";
import { isProd } from "./runtime-environment";
import sentry from "./sentry";

/* eslint-disable no-console */
const getRequestDataString = (message?: unknown) => {
    const requestId = getContinuationRequestId();
    return requestId ? `RequestID= ${requestId} : ${message}` : message;
};

const shouldStringify = (item: any): boolean => {
    const itemType = typeof item;
    return itemType === "object" || itemType === "function" || itemType === "symbol";
};

const parseOptionalParams = (logParams: unknown[]): Array<unknown> => {
    return logParams?.map?.((param) => (shouldStringify(param) ? stringify(param) : param));
};

const log = (message?: unknown, ...optionalParams: unknown[]): void => {
    if (!shouldLogConsoleErrors()) return;

    console.log(getRequestDataString(message), ...parseOptionalParams(optionalParams));
};

const logAlways = (message?: unknown, ...optionalParams: unknown[]): void => {
    console.log(getRequestDataString(message), ...parseOptionalParams(optionalParams));
};

const logInfo = (message?: unknown, ...optionalParams: unknown[]): void => {
    if (!shouldLogConsoleErrors()) return;

    console.info(getRequestDataString(message), ...parseOptionalParams(optionalParams));
};

const logWarn = (message?: unknown, ...optionalParams: unknown[]): void => {
    if (!shouldLogConsoleErrors()) return;

    console.warn(getRequestDataString(message), ...parseOptionalParams(optionalParams));
};

const logRoute = (req, res) => {
    const begin = new Date().getTime();

    logInfo(
        `Next router: received request httpVersion=${req.httpVersion} method=${req.method} url=${req.url} protocol=${req.protocol} host=${req.host} port=${req.port}`
    );

    res?.on?.("close", () => {
        const end = new Date().getTime();
        const diff = end - begin;
        const diffStr = `${diff}ms`;
        logInfo(
            `Next router: sent response service=${diffStr} statusCode=${res.statusCode} statusMessage=${res.statusMessage}`
        );
    });
};

const logError = (message?: unknown, ...optionalParams: unknown[]): void => {
    clientLogError(message);
    logConsoleError(message, ...parseOptionalParams(optionalParams));
};

const clientLogError = (message?: unknown) => {
    if (!shouldLogSentryErrors() || !message) return;

    sentry.captureMessage(message.toString());
};

const logConsoleError = (message?: unknown, ...optionalParams: unknown[]) => {
    if (!shouldLogConsoleErrors()) return;

    console.error(getRequestDataString(message), ...parseOptionalParams(optionalParams));
};

const logException = (error: Error | AxiosError | string, errorDescription?: string): void => {
    if (error === undefined) return; // If undefined, potentially called from useSWR hook when there's no error
    if (!error) logError(error); // For all other no errors, print the faulty value with the description
    if (typeof error === "string") return logError(error, errorDescription); // If the exception passed is a string, log it as an error

    clientLogException(error);
    logConsoleException(error, errorDescription);
};

const clientLogException = (error: Error | AxiosError) => {
    if (!shouldLogSentryErrors()) return; // If shouldn't log any sentry errors
    if ("response" in error && error.response?.status === 404) return; // If this error is AxiosError of 404

    sentry.captureException(error);
};

const logConsoleException = (error: Error, errorDescription?: string) => {
    if (!shouldLogConsoleErrors()) return;

    logConsoleError(
        `error: ${errorDescription ? errorDescription : ""}`,
        `message=${error.message}`
    );
    logConsoleError(`----------- stack begin -----------`);
    logConsoleError(error.stack);
    logConsoleError(`----------- stack end -----------`);
};

const shouldLogSentryErrors = () => !IS_SERVER || isProd();
const shouldLogConsoleErrors = () => IS_SERVER || isProd();

export default {
    log,
    logInfo,
    logWarn,
    logRoute,
    logError,
    logException,
    logAlways,
};
/* eslint-enable no-console */
