import { LD_USER_LOCAL_STORAGE_KEY } from "@/shared/constants";

import { getLocalStorageUserKey } from "@/utils/localStorage";

import { localeCountryCodeMap } from "@/translations/config";

export const getLaunchDarklyProperties = (locale: string): [string, string, string] => {
    const ldKey = getLocalStorageUserKey(LD_USER_LOCAL_STORAGE_KEY),
        country = localeCountryCodeMap[locale],
        clientSideID = process.env.NEXT_PUBLIC_LD_CLIENT_ID;

    return [ldKey, country, clientSideID];
};
