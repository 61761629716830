export type EVENT_PROP = {
    categoryId?: string;
    categoryName?: string;
    collectionName?: string;
    country?: string;
    locale?: string;
    description?: string;
    hasLocations?: boolean;
    hasOffer?: boolean;
    merchantId?: number;
    merchantName?: string;
    outboundLink?: string;
    position?: number;
    productName?: string;
    productPrice?: string;
    query?: string;
    resultsCount?: number;
    searchTerm?: string;
    searchVersion?: string;
    shopUrl?: string;
    sort?: string;
    title?: string;
    footerLinkText?: string;
    footerLink?: string;
    httpCorrelationId?: string;
    adNetwork?: string;
    offerNetwork?: string;
    offerButton?: boolean;
    paid?: boolean;
    offerURL?: string;
};

export const UI_CONTEXTS = {
    HOMEPAGE_BANNER: "homepage-banner",
    HOMEPAGE_TILE: "homepage-tile",
    SEARCH: "search",
    SEARCH_TILE: "search-tile",
    FEATURED_TILE: "featured-tile",
    CATEGORY_TILE: "category-tile",
    COLLECTION_TILE: "category-tile",
    CATEGORY_BANNER: "category-banner",
    RETAILER_PROFILE: "retailer-profile",
    IN_STORE: "in-store",
    BREADCRUMBS: "breadcrumbs",
    CATEGORY_DIRECTORY_FILTER: "category-directory-filter",
    TOP_NAVIGATION: "Navigation Bar",
    FOOTER_NAVIGATION: "footer-navigation",
    SEARCH_AUTO_COMPLETE_LIST: "Search Auto Complete List",
    COLLECTION_CAROUSEL: "collections-carousel",
    COLLECTION_PAGE: "collections-page",
    GIFTCARD_RETAILER_PROFILE: "gift-card-retailer-profile",
    GIFTCARD_BUYFORYOURSELF_FORM: "gift-card-buy-for-yourself-form",
    GIFTCARD_SENDASGIFT_FORM: "gift-card-send-as-gift-form",
};

export type UI_CONTEXT = typeof UI_CONTEXTS[keyof typeof UI_CONTEXTS];

export type ANALYTICS = {
    uiContext: UI_CONTEXT;
    eventName?: string;
    eventProps?: EVENT_PROP;
    searchTerm?: string;
};
export const WEBSITE_VERSION_V2 = "v2";

export const AMPLITUDE_SEARCH_VERSIONS = {
    V3: "SearchV3",
};

export type SEARCH_VERSIONS =
    typeof AMPLITUDE_SEARCH_VERSIONS[keyof typeof AMPLITUDE_SEARCH_VERSIONS];
