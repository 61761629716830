import { HOST, HOST_EN_GB, HOST_EU } from "../shared/constants";
import { locales, countryCodes } from "../translations/config";
import { CountryCode, Locale } from "../types/locale";

export function stringToLocale(code: string): Locale {
    return locales.find((locale) => locale === code);
}

export function hostForLocale(locale: Locale): string {
    switch (locale) {
        case "en-GB":
            return HOST_EN_GB;
        case "it-IT":
        case "fr-FR":
        case "es-ES":
            return HOST_EU;
        default:
            return HOST;
    }
}

export function nextApiHostForLocale(locale: Locale): string {
    return typeof window === "undefined" ? hostForLocale(locale) : window.location.origin;
}

export function isLocale(tested: string): tested is Locale {
    return locales.some((locale) => locale === tested);
}

export function isCountryCode(tested: string): tested is CountryCode {
    return countryCodes.some((countryCode) => countryCode === tested);
}

export const isNewNavEnabled = (locale: Locale): boolean => {
    return locale === "en-US";
};
