const EVENTS = {
    BANNER_CLICK: "Banner Click",
    BUTTON_CLICK: "Button Click",
    CLICK_APPLY_REFINE: "Clicked Apply Refine",
    CLICK_REFINE: "Clicked Refine",
    FILTERING_APPLY_CLICK: "Filtering - Apply - Click",
    FILTERING_REFINE_CLICK: "Filtering - Refine - Click",
    FILTER_INSTORE_CLICK: "Filter - In-store - Click",
    FILTER_OFFER_CLICK: "Filter - Offer - Click",
    FOOTER_LINK_CLICK: "Footer - Link - Click",
    GIFT_CARD_CHECKOUT_COMPLETED: "ShopDirectory - GiftCard - Checkout Complete - View",
    GIFT_CARD_CHECKOUT_START: "ShopDirectory - GiftCard - Checkout Start - View",
    GIFT_CARD_CHECKOUT_SUBMIT: "ShopDirectory - GiftCard - Checkout Submit - View",
    GIFT_CARD_BUY_FOR_YOURSELF_CLICK: "ShopDirectory - GiftCard - Buy For Yourself CTA - Click",
    GIFT_CARD_SEND_AS_GIFT_CLICK: "ShopDirectory - GiftCard - Send As Gift CTA - Click",
    GIFT_CARD_BUY_FOR_YOURSELF_CONFIRM_CLICK:
        "ShopDirectory - GiftCard - Buy For Yourself Confirm CTA - Click",
    GIFT_CARD_SEND_AS_GIFT_CONTINUE_CLICK:
        "ShopDirectory - GiftCard - Send As Gift Continue CTA - Click",
    GIFT_CARD_PAYMENT_START: "ShopDirectory - GiftCard - Payment Start - View",
    GIFT_CARD_VIEW: "ShopDirectory - GiftCard - Viewed - View",
    SHOP_DIRECTORY_CATEGORY_LIST_CLICK: "ShopDirectory - Category List - Click",
    SHOP_DIRECTORY_CATEGORY_LIST_INSTORE_CLICK: "ShopDirectory - Category List - In-store - Click",
    SHOP_DIRECTORY_CATEGORY_LIST_VIEW: "ShopDirectory - Category List - View",
    SHOP_DIRECTORY_COLLECTION_LIST_CLICK: "ShopDirectory - Collection List - Click",
    SHOP_DIRECTORY_CLICK: "ShopDirectory - Store - Click",
    SHOP_DIRECTORY_PAGE_LOADING: "ShopDirectory - Page - loading",
    SHOP_DIRECTORY_PAGE_LOADED: "ShopDirectory - Page",
    SHOP_DIRECTORY_PRODUCT_CLICK: "ShopDirectory - Product - Click",
    SHOP_DIRECTORY_SEARCH_BAR_CLICK: "ShopDirectory - Search Bar - Click",
    SHOP_DIRECTORY_SEARCH_RESULT_VIEW: "ShopDirectory - Search Results - View",
    SHOP_DIRECTORY_SEARCH_TERM_CLICK: "ShopDirectory - Search Term - Click",
    SHOP_DIRECTORY_SMART_LINK: "ShopDirectory - Smart Link",
    SORT_CLICK: "Sort - Click",
    WEB_LOGGED_IN_USER_SHOWN_WELCOME_MODAL: "Web Logged-In - User - Shown Welcome Modal",
    WEB_LOGGED_IN_HEADER_BUTTON_CLICK: "Web Logged-In Header - Button Click",
    WEB_LOGGED_IN_HEADER_HOME: "Web Logged-In Header - Home",
    WEB_LOGGED_IN_HEADER_UPCOMING_PAYMENTS: "Web Logged-In Header - Upcoming Payments",
    WEB_LOGGED_IN_HEADER_ORDERS: "Web Logged-In Header - Orders",
    WEB_LOGGED_IN_HEADER_HELP: "Web Logged-In Header - Help",
    WEB_LOGGED_IN_HEADER_ACCOUNT: "Web Logged-In Header - Account",
    WEB_LOGGED_IN_HEADER_LOG_OUT: "Web Logged-In Header - Log Out",
};

export default EVENTS;
