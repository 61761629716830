module.exports = {
    i18n: {
        defaultLocale: "global",
        locales: [
            "en-AU",
            "en-CA",
            "en-GB",
            "en-NZ",
            "en-US",
            "fr-CA",
            "fr-FR",
            "it-IT",
            "es-ES",
            "global",
        ],
        localeDetection: false,
    },
    react: {
        useSuspense: false,
    },
    saveMissing: true,
    ns: ["translations"],
    defaultNS: "translations",
    localePath: "./translations/locales/",
    preload: ["en-AU", "en-CA", "en-GB", "en-NZ", "en-US", "fr-CA", "global"],
};
