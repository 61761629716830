import { v4 as uuidV4 } from "uuid";

import fillLocalStorage from "@/polyfills/localStoragePolyfill";

const ls = fillLocalStorage();

export const getLocalStorageKey = (key: string): string | null => {
    if (typeof window === "undefined") return null;

    return ls.getItem(key);
};

export const setLocalStorageKey = (key: string, value: unknown): string => {
    if (typeof window === "undefined") return null;
    let strVal;
    try {
        strVal = JSON.stringify(value);
    } catch {
        strVal = value?.toString();
    }

    ls.setItem(key, strVal);
    return strVal;
};

export const getLocalStorageUserKey = (key: string): string | null => {
    const userKey = getLocalStorageKey(key);
    if (!userKey) return setLocalStorageUserKey(key);

    return userKey;
};

const setLocalStorageUserKey = (key: string): string | null => {
    const newUserKey = uuidV4();
    return setLocalStorageKey(key, newUserKey);
};
