import { Locale } from "../types/locale";

export const defaultLocale = "global" as const;

export const locales = [
    "en-US",
    "en-AU",
    "en-NZ",
    "en-GB",
    "en-CA",
    "fr-CA",
    "fr-FR",
    "es-ES",
    "it-IT",
    "global",
] as const;
export const exportedLocales = [
    "enUS",
    "enAU",
    "enNZ",
    "enGB",
    "enCA",
    "frCA",
    "frFR",
    "esES",
    "itIT",
    "global",
] as const;
export const countryCodes = ["US", "AU", "NZ", "GB", "CA", "FR", "ES", "IT", "global"] as const;
export const languages = ["English", "Français", "Español", "Italiano", "global"] as const;
export const countryNames = [
    "United States",
    "Australia",
    "New Zealand",
    "United Kingdom",
    "Canada",
    "France",
    "España",
    "Italia",
    "global",
] as const;
export const currencyCodes = ["USD", "AUD", "NZD", "GBP", "CAD", "EUR", "global"] as const;
export const currencySymbols = ["$", "£", "$", "€", "global"] as const;

type LocaleMap<T> = {
    [key in Locale]: T;
};

type CurrencyCodeMap<T> = {
    [key in typeof currencyCodes[number]]: T;
};

export const languageNames: LocaleMap<typeof languages[number]> = {
    "en-US": "English",
    "en-AU": "English",
    "en-NZ": "English",
    "en-GB": "English",
    "en-CA": "English",
    "fr-CA": "Français",
    "fr-FR": "Français",
    "es-ES": "Español",
    "it-IT": "Italiano",
    global: "global",
};

export const localeCountryCodeMap: LocaleMap<typeof countryCodes[number]> = {
    "en-US": "US",
    "en-AU": "AU",
    "en-NZ": "NZ",
    "en-GB": "GB",
    "en-CA": "CA",
    "fr-CA": "CA",
    "fr-FR": "FR",
    "es-ES": "ES",
    "it-IT": "IT",
    global: "global",
};

export const localeCountryNameMap: LocaleMap<typeof countryNames[number]> = {
    "en-US": "United States",
    "en-AU": "Australia",
    "en-NZ": "New Zealand",
    "en-GB": "United Kingdom",
    "en-CA": "Canada",
    "fr-CA": "Canada",
    "fr-FR": "France",
    "es-ES": "España",
    "it-IT": "Italia",
    global: "global",
};

export const localeCurrencyCodeMap: LocaleMap<typeof currencyCodes[number]> = {
    "en-US": "USD",
    "en-AU": "AUD",
    "en-NZ": "NZD",
    "en-GB": "GBP",
    "en-CA": "CAD",
    "fr-CA": "CAD",
    "fr-FR": "EUR",
    "es-ES": "EUR",
    "it-IT": "EUR",
    global: "global",
};

export const localeCurrencySymbolMap: LocaleMap<typeof currencySymbols[number]> = {
    "en-US": "$",
    "en-AU": "$",
    "en-NZ": "$",
    "en-GB": "£",
    "en-CA": "$",
    "fr-CA": "$",
    "fr-FR": "€",
    "es-ES": "€",
    "it-IT": "€",
    global: "global",
};

export const currencyCodeToCurrencySymbolMap: CurrencyCodeMap<typeof currencySymbols[number]> = {
    USD: "$",
    AUD: "$",
    NZD: "$",
    GBP: "£",
    CAD: "$",
    EUR: "€",
    global: "global",
};
