import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import EVENTS from "@/analytics/events";
import { trackPageLoad } from "@/analytics/tracking";

import { useIsAuthUserDataLoaded } from "@/context/AuthContext";
import { useCurrentLocale } from "@/context/LanguageContext";

const AppLoaded: React.FC = ({ children }) => {
    const router = useRouter();
    const locale = useCurrentLocale();
    const isAuthUserDataLoaded = useIsAuthUserDataLoaded();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!isLoaded && locale && router.asPath && isAuthUserDataLoaded) {
            setIsLoaded(true);
            trackPageLoad(router.asPath, locale, EVENTS.SHOP_DIRECTORY_PAGE_LOADED);
        }
    }, [isLoaded, locale, router.asPath, isAuthUserDataLoaded]);

    return <>{children}</>;
};

export default AppLoaded;
