/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-empty-function */
import { v4 as uuidV4 } from "uuid";

const POLYFILL_STORAGE: Storage = {
    length: 0,
    clear: () => {},
    getItem: (key: string) => {
        return null;
    },
    key: (i: number) => {
        return null;
    },
    removeItem: (key: string) => {},
    setItem: (key: string, value: string) => {},
};

const fillLocalStorage = (): Storage => {
    try {
        // Test web storage existence.
        if (!window || !window.localStorage || !window.sessionStorage) throw "exception";

        const testKey = uuidV4();
        // Test web storage accessibility - Needed for Safari private browsing.
        localStorage.setItem(testKey, "1");
        localStorage.removeItem(testKey);

        // local storage works properly, no need to polyfill.
        return window.localStorage;
    } catch (e) {
        return POLYFILL_STORAGE;
    }
};

export default fillLocalStorage;
