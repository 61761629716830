import amplitude, { AmplitudeClient } from "amplitude-js";
import Cookies from "js-cookie";

import logger from "@/utils/logger";

import { AuthUserData } from "@/types/auth";
import { Locale } from "@/types/locale";

import { localeCountryCodeMap } from "../translations/config";
import { UI_CONTEXT, WEBSITE_VERSION_V2 } from "./constants";
import EVENTS from "./events";

const COOKIE_DURATION_MINUTES = 43200;
const isBrowser = () => typeof window !== "undefined";

let _amplitudeInstance: amplitude.AmplitudeClient;
const getAmplitudeInstance = (): amplitude.AmplitudeClient | undefined => {
    if (!isBrowser()) {
        return;
    } else if (_amplitudeInstance) {
        return _amplitudeInstance;
    }

    _amplitudeInstance = amplitude.getInstance();
    _amplitudeInstance.init(process.env.NEXT_PUBLIC_AMPLITUDE_CLIENT_ID, null, {
        includeUtm: true,
        includeReferrer: true,
        unsetParamsReferrerOnNewSession: true,
        includeGclid: true,
    });

    setDeviceIdCookie(_amplitudeInstance);
    return _amplitudeInstance;
};

export const trackPageLoad = (
    path: string,
    locale: string,
    eventName?: string,
    eventProps?: Record<string, unknown>
): void => {
    try {
        const amplitudeInstance = getAmplitudeInstance();
        if (!amplitudeInstance) return;

        amplitudeInstance.logEvent(eventName || EVENTS.SHOP_DIRECTORY_PAGE_LOADING, {
            pageLoaded: true,
            websiteVersion: WEBSITE_VERSION_V2,
            path,
            country: localeCountryCodeMap[locale],
            locale,
            ...eventProps,
        });
    } catch (e) {
        logger.logException(e, "failed to track page load");
    }
};

const setDeviceIdCookie = (amplitudeInstance: AmplitudeClient) => {
    const { deviceId } = amplitudeInstance.options;
    Cookies.set("deviceId", deviceId, {
        domain: window.location.host,
        expires: COOKIE_DURATION_MINUTES,
    });
};

export const trackUserEvent = (
    locale: string,
    path: string,
    uiContext: UI_CONTEXT,
    eventName = EVENTS.BUTTON_CLICK,
    eventProps?: Record<string, unknown>
): void => {
    const amplitudeInstance = getAmplitudeInstance();
    if (!amplitudeInstance) return;

    amplitudeInstance.logEvent(eventName, {
        country: localeCountryCodeMap[locale],
        locale,
        websiteVersion: WEBSITE_VERSION_V2,
        context: uiContext,
        path,
        ...eventProps,
    });
};

export const setUserDataProperties = (
    userData: AuthUserData,
    userProperties: Record<string, unknown> = {}
) => {
    const amplitudeInstance = getAmplitudeInstance();
    if (!amplitudeInstance) return;

    userProperties.isLoggedIn = !!userData;
    if (userProperties.isLoggedIn) {
        const userId = userData?.uuid;
        userId && _amplitudeInstance.setUserId(userId);
        userProperties.id = userData?.id;
    }

    setUserProperties(userProperties);
};

export const setUserProperties = (userProperties: Record<string, unknown> = {}) => {
    const amplitudeInstance = getAmplitudeInstance();
    if (!amplitudeInstance) return;

    _amplitudeInstance.setUserProperties(userProperties);
};

export const setBuilderVariationProperty = (
    locale: Locale,
    builderContentPath: string,
    variant: string
) => {
    if (!variant) return;
    const variantProperty = {
        [`${locale}${builderContentPath}`]: variant,
    };

    setUserProperties(variantProperty);
};
